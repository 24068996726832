import React, { Fragment } from "react";
import NavBar from "../components/NavBar.jsx";
import TitleBox, { TitleBoxHeights } from "../components/TitleBox.jsx";
import {
  GridItemPhotoStuff,
  getParagraphItem,
  GridItemCardList,
  CardListContainer,
} from "../components/MainPageGridItem.jsx";
import ItemCard, {
  ResponsiveTitleContentCard,
} from "../components/ResponsiveItemCard.jsx";
import Grid from "@material-ui/core/Grid";
import { parseWithFallback } from "../components/util/Functions";
import Footer from "../components/Footer.jsx";
import { Helmet } from "react-helmet";

import { graphql } from "gatsby";

/** @jsx jsx */
import { jsx, Text } from "theme-ui";

export const query = graphql`
  query($treatmentID: ID!) {
    keystone {
      Treatment(where: { id: $treatmentID }) {
        title
        metaDesc
        coverImage {
          publicUrl
        }
        paragraphs(sortBy: order_ASC) {
          content
          order
          title
          image {
            publicUrl
          }
        }
        services(where: { draft: false }) {
          title
          slug
          coverImage {
            publicUrl
          }
        }
        topics(where: { draft: false }) {
          title
          slug
          coverImage {
            publicUrl
          }
        }
        doctors(where: { draft: false }) {
          name
          specialization
          slug
          coverImage {
            publicUrl
          }
        }
      }
    }
  }
`;

const TopicTemplate = ({ data }) => {
  const treatment = data.keystone.Treatment;
  const paragraphs = treatment.paragraphs;
  const services = treatment.services;
  const topics = treatment.topics;
  const doctors = treatment.doctors;
  var itemsNumber = paragraphs.length + 1;
  return (
    <Fragment>
      <Helmet>
        <title>{treatment.name} - Prestazione PG</title>
        <meta
          name="description"
          content={
            "Poliambulatorio Giano - Prestazione - " + treatment.metaDesc
          }
        />
      </Helmet>
      <NavBar />
      <TitleBox heights={TitleBoxHeights.contentPageHeights}>
        <Text variant="pagesTitle">Le Nostre Prestazioni</Text>
      </TitleBox>
      <Grid container>
        <GridItemPhotoStuff
          colorNumber={0}
          img={
            treatment.coverImage ? treatment.coverImage.publicUrl : undefined
          }
          centerHMode={true}
        >
          <h1
            sx={{ variant: "text.paragraphTitle", color: "black", margin: 0 }}
          >
            {treatment.title}
          </h1>
        </GridItemPhotoStuff>
        {paragraphs.map((p, i) =>
          getParagraphItem(
            p.title,
            parseWithFallback(p.content),
            (1 + i) % 2,
            (1 + i) % 2 == 0,
            p.image,
            i
          )
        )}
        {services.length > 0 && (
          <GridItemCardList
            colorNumber={itemsNumber++}
            title="Servizi Correlati"
            collapsable={true}
          >
            {services.map((x, i) => (
              <CardListContainer key={i} navigate={x.slug} queryText={x.title}>
                <ItemCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                />
              </CardListContainer>
            ))}
          </GridItemCardList>
        )}
        {doctors.length > 0 && (
          <GridItemCardList
            colorNumber={itemsNumber++}
            title="Medici Correlati"
            collapsable={true}
          >
            {doctors.map((x, i) => (
              <CardListContainer key={i} navigate={x.slug} queryText={x.name}>
                <ResponsiveTitleContentCard
                  title={x.name}
                  content={x.specialization}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                />
              </CardListContainer>
            ))}
          </GridItemCardList>
        )}
        {topics.length > 0 && (
          <GridItemCardList
            colorNumber={itemsNumber++}
            title="Ambiti Clinici Correlati"
            collapsable={true}
          >
            {topics.map((x, i) => (
              <CardListContainer key={i} navigate={x.slug} queryText={x.title}>
                <ItemCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                />
              </CardListContainer>
            ))}
          </GridItemCardList>
        )}
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default TopicTemplate;
